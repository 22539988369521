import Button1 from "./Button";
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from "./pages/Home";
import Game from "./pages/Game";
import { Image } from "@mantine/core";
import { useState } from "react";



const flagMap: { [key: string]: string } = {
    nl: 'nl',
    en: 'gb',
  };

export default function Render() {
    const [flag, setFlag] = useState<'nl' | 'en'>('nl');

    const toggleFlag = () => {
      setFlag((prevFlag) => (prevFlag === 'nl' ? 'en' : 'nl'));
    };

    // Update currentFlag based on flag value
    let currentFlag: string = flag;
    if (currentFlag === 'en') {
        currentFlag = 'gb';
    }
    const link = "http://purecatamphetamine.github.io/country-flag-icons/3x2/" + currentFlag.toUpperCase() + ".svg"
    return (
        <Router>
            <div className="bgo">
                <div className="absolute mt-5 flag"> <button   onClick={toggleFlag}>   <Image src={link} width={43} ></Image> </button> </div>
                <div className="flex justify-end items-center mr-5">
                   
                <p className="logo float-right text-gray-300 mr-5 ">Haloo Design  </p>  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1080 1080" >
                        <style>
                            {`
            .st0{fill:none;stroke:#FABD6C;stroke-width:30;stroke-miterlimit:10;}
            .st1{fill:#F59D27;}
            `}
                        </style>
                        <path className="st0" d="M882.89,37.08c225.46,248.95,206.38,633.6-30.32,851.87c-223.28,205.9-580.69,208.85-815.49-3.03" />
                        <path className="st1" d="M931.4,598.68c14.01-9.48,31.82-30.1,36.12-81.12c2.73-32.35,4.33-56.68-0.97-105.28
        c-7.97-73.12-12.48-109.96-38.19-123.58c-17-9.01-36.81-5.06-50.02-0.76c-118.44,44.55-236.88,89.09-355.33,133.64
        c-4.61,1.35-23.27,7.22-25.52,19.83c-1.14,6.37-1.6,6.58,3.56,13.71s119.75,49.85,182.58,75.86S885.34,629.84,931.4,598.68z" />
                        <path className="st1" d="M233.95,878.32c-3.8,3.81-9.84,8.77-18.27,11.49c-8.92,2.88-24.84,4.63-68.47-22.6
        c-38.02-23.72-59.8-37.31-58.32-54.08c0.59-6.66,4.46-9.53,29.55-34.66c10.44-10.45,20.49-21.28,31.04-31.61
        c72.75-71.24,145.51-142.48,218.26-213.71c9.82-9.76,34.21-33.79,37.51-34.23c1.12-0.15,1.59,0.32,1.59,0.32
        c0.94,0.93-0.12,3.43-0.62,4.54c-1.77,3.97-31.28,68.58-71.71,156.97C300.99,733.28,267.47,805.8,233.95,878.32z" />
                        <path className="st1" d="M885.42,234.64c1.32-1.28,2.52-2.65,2.52-2.65s5.83-6.64,9.1-15.54c7.62-20.71-22.12-68.63-22.12-68.63
        c-23.46-38.18-36.89-60.06-53.67-58.7c-6.66,0.54-9.56,4.39-34.86,29.31c-10.52,10.36-21.42,20.34-31.83,30.82
        c-71.75,72.25-143.49,144.51-215.24,216.76c-9.83,9.75-34.03,33.97-34.5,37.26c-0.16,1.12,0.31,1.6,0.31,1.6
        c0.92,0.95,3.43-0.1,4.54-0.59c141.32-61.92,157.47-70.61,157.47-70.61C727.97,301.42,875.24,244.54,885.42,234.64z" />
                        <path className="st1" d="M677.97,897.27c16.6,3.3,43.78,1.45,83.07-31.36c24.92-20.81,43.35-36.78,74.17-74.73
        c46.38-57.09,69.39-86.2,61-114.06c-5.55-18.42-22.29-29.73-34.64-36.1c-114.96-52.88-229.93-105.76-344.89-158.64
        c-4.2-2.33-21.5-11.46-32.04-4.19c-5.32,3.67-5.81,3.49-7.25,12.17c-1.44,8.68,48.77,120.19,74.47,183.15
        C577.55,736.48,623.42,886.43,677.97,897.27z" />
                        <path className="st1" d="M304.21,939.83c9.93,13.7,31.12,30.82,82.25,33.47c32.42,1.68,56.79,2.49,105.2-4.39
        c72.82-10.34,109.49-16.05,122.27-42.19c8.45-17.28,3.86-36.95-0.87-50.02c-48.37-116.93-96.75-233.86-145.12-350.8
        c-1.5-4.56-7.98-23.02-20.65-24.86c-6.4-0.93-6.63-1.39-13.59,4s-45.93,121.31-69.88,184.95
        C339.86,753.65,271.57,894.81,304.21,939.83z" />
                        <path className="st1" d="M976.37,294.77" />
                    </svg>
                    </div>
            </div>
            
            <Routes>
                <Route path="/" element={<Home flag={flag} />} />
                <Route path="/Game" element={<Game />} />
            </Routes>
   
            

            <div className="whatsapp-widget">
            <div className="whatsapp-notification"><span>1</span></div>
            <a className="whatsapp-widget-link  flex justify-center items-center " href="https://wa.me/+310643693567" target="_blank" >  <Image  src={require('./img/whatsapp-icon.png')} width={250} ></Image></a>
            </div>
        </Router>
    );
}
ReactDOM.render(<Render />, document.getElementById('root'));