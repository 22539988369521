import '../tailwind.css'; // Import the Tailwind CSS file
import { Image } from '@mantine/core';
import React, { Component } from 'react';
import jsonData from '../lang/home.json'; // Import the home.json file

// Define the props interface to include the 'value' prop
interface HomeProps {
    flag: string;
  }

  
class Home extends Component<HomeProps> {
    
    lang = 'en'

    state = {
        currentLanguage: this.lang as keyof typeof jsonData.home,
        currentCurrency: 'eur' as keyof typeof jsonData.currency,
      };
    
      // Function to switch language
      switchLanguage = (language: keyof typeof jsonData.home) => {
        this.setState({ currentLanguage: language });
      };
    
      // Function to switch currency
      switchCurrency = (currency: keyof typeof jsonData.currency) => {
        this.setState({ currentCurrency: currency });
      };
    
      componentDidUpdate(prevProps: HomeProps) {
        const { flag } = this.props;
        const prevFlag = prevProps.flag;
        
        if (flag !== prevFlag) {
          // Only update the state if the 'flag' prop has changed
          if (flag === 'nl') {
            this.setState({ currentLanguage: 'en' });
          } else if (flag === 'en') {
            this.setState({ currentLanguage: 'nl' });
          }
        }
      }

      render() {

        const { currentLanguage, currentCurrency } = this.state;
        
        const { flag } = this.props;
   
        // Get the title based on the selected language
        const data = jsonData.home[currentLanguage];
    
        // Access the currency object from the home object
        const currency = jsonData.currency[currentCurrency];
        // console.log(currency); 
    return (
        <>  
            <div className="main">
                <div data-aos="fade-in"
                        data-aos-easing="ease-in"
                        data-aos-delay="400"
                        data-aos-duration="700"
                        data-aos-offset="-200">
                <div className='orange1 hidden md:block'>
                    <div className="img-container">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1080 1080" >
                        <style>
                            {`
            .st0{fill:none;stroke:#FABD6C;stroke-width:30;stroke-miterlimit:10;}
            .st1{fill:#F59D27;}
            `}
                        </style>
                        <path className="st0" d="M882.89,37.08c225.46,248.95,206.38,633.6-30.32,851.87c-223.28,205.9-580.69,208.85-815.49-3.03" />
                        <path className="st1" d="M931.4,598.68c14.01-9.48,31.82-30.1,36.12-81.12c2.73-32.35,4.33-56.68-0.97-105.28
        c-7.97-73.12-12.48-109.96-38.19-123.58c-17-9.01-36.81-5.06-50.02-0.76c-118.44,44.55-236.88,89.09-355.33,133.64
        c-4.61,1.35-23.27,7.22-25.52,19.83c-1.14,6.37-1.6,6.58,3.56,13.71s119.75,49.85,182.58,75.86S885.34,629.84,931.4,598.68z" />
                        <path className="st1" d="M233.95,878.32c-3.8,3.81-9.84,8.77-18.27,11.49c-8.92,2.88-24.84,4.63-68.47-22.6
        c-38.02-23.72-59.8-37.31-58.32-54.08c0.59-6.66,4.46-9.53,29.55-34.66c10.44-10.45,20.49-21.28,31.04-31.61
        c72.75-71.24,145.51-142.48,218.26-213.71c9.82-9.76,34.21-33.79,37.51-34.23c1.12-0.15,1.59,0.32,1.59,0.32
        c0.94,0.93-0.12,3.43-0.62,4.54c-1.77,3.97-31.28,68.58-71.71,156.97C300.99,733.28,267.47,805.8,233.95,878.32z" />
                        <path className="st1" d="M885.42,234.64c1.32-1.28,2.52-2.65,2.52-2.65s5.83-6.64,9.1-15.54c7.62-20.71-22.12-68.63-22.12-68.63
        c-23.46-38.18-36.89-60.06-53.67-58.7c-6.66,0.54-9.56,4.39-34.86,29.31c-10.52,10.36-21.42,20.34-31.83,30.82
        c-71.75,72.25-143.49,144.51-215.24,216.76c-9.83,9.75-34.03,33.97-34.5,37.26c-0.16,1.12,0.31,1.6,0.31,1.6
        c0.92,0.95,3.43-0.1,4.54-0.59c141.32-61.92,157.47-70.61,157.47-70.61C727.97,301.42,875.24,244.54,885.42,234.64z" />
                        <path className="st1" d="M677.97,897.27c16.6,3.3,43.78,1.45,83.07-31.36c24.92-20.81,43.35-36.78,74.17-74.73
        c46.38-57.09,69.39-86.2,61-114.06c-5.55-18.42-22.29-29.73-34.64-36.1c-114.96-52.88-229.93-105.76-344.89-158.64
        c-4.2-2.33-21.5-11.46-32.04-4.19c-5.32,3.67-5.81,3.49-7.25,12.17c-1.44,8.68,48.77,120.19,74.47,183.15
        C577.55,736.48,623.42,886.43,677.97,897.27z" />
                        <path className="st1" d="M304.21,939.83c9.93,13.7,31.12,30.82,82.25,33.47c32.42,1.68,56.79,2.49,105.2-4.39
        c72.82-10.34,109.49-16.05,122.27-42.19c8.45-17.28,3.86-36.95-0.87-50.02c-48.37-116.93-96.75-233.86-145.12-350.8
        c-1.5-4.56-7.98-23.02-20.65-24.86c-6.4-0.93-6.63-1.39-13.59,4s-45.93,121.31-69.88,184.95
        C339.86,753.65,271.57,894.81,304.21,939.83z" />
                        <path className="st1" d="M976.37,294.77" />
                    </svg>
                    </div>

                </div>

                </div>
                <div className="container">
                    <div className='main flex justify-start items-center'>
                        <div className='updown'>
                            <h1 className="title mb-0" data-aos="fade-up"
                                data-aos-easing="ease-in-back"
                                data-aos-delay="100"
                                data-aos-duration="600"
                                
                                data-aos-offset="0"> { data.title } </h1>
                            <p className="title2 text-md mt-0 mb-80 " data-aos="fade-left"
                                data-aos-easing="ease-in-back"
                                data-aos-delay="400"
                                data-aos-duration="800"
                                
                                data-aos-offset="0" >{ data.title2 }</p>
                        </div>

                    </div>


                </div>
                <div data-aos="fade-in"
                        data-aos-easing="ease-in"
                        data-aos-delay="1100"
                        data-aos-duration="800"
                        data-aos-offset="-700">
                <div className='orange3 hidden md:block'>
                    <div className="img-container">

                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1080 1080">
                        <style>
                            {`
            .st0{fill:none;stroke:#FABD6C;stroke-width:30;stroke-miterlimit:10;}
            .st1{fill:#F59D27;}
            `}
                        </style>
                        <path className="st0" d="M882.89,37.08c225.46,248.95,206.38,633.6-30.32,851.87c-223.28,205.9-580.69,208.85-815.49-3.03" />
                        <path className="st1" d="M931.4,598.68c14.01-9.48,31.82-30.1,36.12-81.12c2.73-32.35,4.33-56.68-0.97-105.28
        c-7.97-73.12-12.48-109.96-38.19-123.58c-17-9.01-36.81-5.06-50.02-0.76c-118.44,44.55-236.88,89.09-355.33,133.64
        c-4.61,1.35-23.27,7.22-25.52,19.83c-1.14,6.37-1.6,6.58,3.56,13.71s119.75,49.85,182.58,75.86S885.34,629.84,931.4,598.68z" />
                        <path className="st1" d="M233.95,878.32c-3.8,3.81-9.84,8.77-18.27,11.49c-8.92,2.88-24.84,4.63-68.47-22.6
        c-38.02-23.72-59.8-37.31-58.32-54.08c0.59-6.66,4.46-9.53,29.55-34.66c10.44-10.45,20.49-21.28,31.04-31.61
        c72.75-71.24,145.51-142.48,218.26-213.71c9.82-9.76,34.21-33.79,37.51-34.23c1.12-0.15,1.59,0.32,1.59,0.32
        c0.94,0.93-0.12,3.43-0.62,4.54c-1.77,3.97-31.28,68.58-71.71,156.97C300.99,733.28,267.47,805.8,233.95,878.32z" />
                        <path className="st1" d="M885.42,234.64c1.32-1.28,2.52-2.65,2.52-2.65s5.83-6.64,9.1-15.54c7.62-20.71-22.12-68.63-22.12-68.63
        c-23.46-38.18-36.89-60.06-53.67-58.7c-6.66,0.54-9.56,4.39-34.86,29.31c-10.52,10.36-21.42,20.34-31.83,30.82
        c-71.75,72.25-143.49,144.51-215.24,216.76c-9.83,9.75-34.03,33.97-34.5,37.26c-0.16,1.12,0.31,1.6,0.31,1.6
        c0.92,0.95,3.43-0.1,4.54-0.59c141.32-61.92,157.47-70.61,157.47-70.61C727.97,301.42,875.24,244.54,885.42,234.64z" />
                        <path className="st1" d="M677.97,897.27c16.6,3.3,43.78,1.45,83.07-31.36c24.92-20.81,43.35-36.78,74.17-74.73
        c46.38-57.09,69.39-86.2,61-114.06c-5.55-18.42-22.29-29.73-34.64-36.1c-114.96-52.88-229.93-105.76-344.89-158.64
        c-4.2-2.33-21.5-11.46-32.04-4.19c-5.32,3.67-5.81,3.49-7.25,12.17c-1.44,8.68,48.77,120.19,74.47,183.15
        C577.55,736.48,623.42,886.43,677.97,897.27z" />
                        <path className="st1" d="M304.21,939.83c9.93,13.7,31.12,30.82,82.25,33.47c32.42,1.68,56.79,2.49,105.2-4.39
        c72.82-10.34,109.49-16.05,122.27-42.19c8.45-17.28,3.86-36.95-0.87-50.02c-48.37-116.93-96.75-233.86-145.12-350.8
        c-1.5-4.56-7.98-23.02-20.65-24.86c-6.4-0.93-6.63-1.39-13.59,4s-45.93,121.31-69.88,184.95
        C339.86,753.65,271.57,894.81,304.21,939.83z" />
                        <path className="st1" d="M976.37,294.77" />
                    </svg>
                    </div>
                </div>
                </div>
                <div data-aos="fade-in"
                        data-aos-easing="ease-in"
                        data-aos-delay="800"
                        data-aos-duration="700"
                        data-aos-offset="-200">
                <div className='orange2 hidden md:block'>
                    <div className="img-container">

                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1080 1080">
                        <style>
                            {`
            .st0{fill:none;stroke:#FABD6C;stroke-width:30;stroke-miterlimit:10;}
            .st1{fill:#F59D27;}
            `}
                        </style>
                        <path className="st0" d="M882.89,37.08c225.46,248.95,206.38,633.6-30.32,851.87c-223.28,205.9-580.69,208.85-815.49-3.03" />
                        <path className="st1" d="M931.4,598.68c14.01-9.48,31.82-30.1,36.12-81.12c2.73-32.35,4.33-56.68-0.97-105.28
        c-7.97-73.12-12.48-109.96-38.19-123.58c-17-9.01-36.81-5.06-50.02-0.76c-118.44,44.55-236.88,89.09-355.33,133.64
        c-4.61,1.35-23.27,7.22-25.52,19.83c-1.14,6.37-1.6,6.58,3.56,13.71s119.75,49.85,182.58,75.86S885.34,629.84,931.4,598.68z" />
                        <path className="st1" d="M233.95,878.32c-3.8,3.81-9.84,8.77-18.27,11.49c-8.92,2.88-24.84,4.63-68.47-22.6
        c-38.02-23.72-59.8-37.31-58.32-54.08c0.59-6.66,4.46-9.53,29.55-34.66c10.44-10.45,20.49-21.28,31.04-31.61
        c72.75-71.24,145.51-142.48,218.26-213.71c9.82-9.76,34.21-33.79,37.51-34.23c1.12-0.15,1.59,0.32,1.59,0.32
        c0.94,0.93-0.12,3.43-0.62,4.54c-1.77,3.97-31.28,68.58-71.71,156.97C300.99,733.28,267.47,805.8,233.95,878.32z" />
                        <path className="st1" d="M885.42,234.64c1.32-1.28,2.52-2.65,2.52-2.65s5.83-6.64,9.1-15.54c7.62-20.71-22.12-68.63-22.12-68.63
        c-23.46-38.18-36.89-60.06-53.67-58.7c-6.66,0.54-9.56,4.39-34.86,29.31c-10.52,10.36-21.42,20.34-31.83,30.82
        c-71.75,72.25-143.49,144.51-215.24,216.76c-9.83,9.75-34.03,33.97-34.5,37.26c-0.16,1.12,0.31,1.6,0.31,1.6
        c0.92,0.95,3.43-0.1,4.54-0.59c141.32-61.92,157.47-70.61,157.47-70.61C727.97,301.42,875.24,244.54,885.42,234.64z" />
                        <path className="st1" d="M677.97,897.27c16.6,3.3,43.78,1.45,83.07-31.36c24.92-20.81,43.35-36.78,74.17-74.73
        c46.38-57.09,69.39-86.2,61-114.06c-5.55-18.42-22.29-29.73-34.64-36.1c-114.96-52.88-229.93-105.76-344.89-158.64
        c-4.2-2.33-21.5-11.46-32.04-4.19c-5.32,3.67-5.81,3.49-7.25,12.17c-1.44,8.68,48.77,120.19,74.47,183.15
        C577.55,736.48,623.42,886.43,677.97,897.27z" />
                        <path className="st1" d="M304.21,939.83c9.93,13.7,31.12,30.82,82.25,33.47c32.42,1.68,56.79,2.49,105.2-4.39
        c72.82-10.34,109.49-16.05,122.27-42.19c8.45-17.28,3.86-36.95-0.87-50.02c-48.37-116.93-96.75-233.86-145.12-350.8
        c-1.5-4.56-7.98-23.02-20.65-24.86c-6.4-0.93-6.63-1.39-13.59,4s-45.93,121.31-69.88,184.95
        C339.86,753.65,271.57,894.81,304.21,939.83z" />
                        <path className="st1" d="M976.37,294.77" />
                    </svg>
                </div>
                </div>
                </div>
            </div>

           

            <div className="fto md:flex justify-between">
                <div className="md:w-1/4 ft md:mt-0 title">
                   
                   <p className="font-medium  text-center text-black"><span dangerouslySetInnerHTML={{__html: data.footer.p1.title}}></span></p> 
                   <p className="txsmall"><span dangerouslySetInnerHTML={{__html: data.footer.p1.data}}></span></p>
                </div>
                <div className="md:w-1/4  ft md:mt-0 title text-center ">
                <p className="font-medium text-black" ><span dangerouslySetInnerHTML={{__html: data.footer.p2.title}}></span></p>
                    <div className="instagram">
                        <a href="https://instagram.com/haloodesign" target="_blank">
                        <svg xmlns="http://www.w3.org/2000/svg" width="144.00" height="144">
                            
                            <path fill="url(#c)" d="M65.03 0C37.888 0 29.95.028 28.407.156c-5.57.463-9.036 1.34-12.812 3.22-2.91 1.445-5.205 3.12-7.47 5.468C4 13.126 1.5 18.394.595 24.656c-.44 3.04-.568 3.66-.594 19.188-.01 5.176 0 11.988 0 21.125 0 27.12.03 35.05.16 36.59.45 5.42 1.3 8.83 3.1 12.56 3.44 7.14 10.01 12.5 17.75 14.5 2.68.69 5.64 1.07 9.44 1.25 1.61.07 18.02.12 34.44.12 16.42 0 32.84-.02 34.41-.1 4.4-.207 6.955-.55 9.78-1.28 7.79-2.01 14.24-7.29 17.75-14.53 1.765-3.64 2.66-7.18 3.065-12.317.088-1.12.125-18.977.125-36.81 0-17.836-.04-35.66-.128-36.78-.41-5.22-1.305-8.73-3.127-12.44-1.495-3.037-3.155-5.305-5.565-7.624C116.9 4 111.64 1.5 105.372.596 102.335.157 101.73.027 86.19 0H65.03z" transform="translate(1.004 1)"/>
                            <path fill="url(#d)" d="M65.03 0C37.888 0 29.95.028 28.407.156c-5.57.463-9.036 1.34-12.812 3.22-2.91 1.445-5.205 3.12-7.47 5.468C4 13.126 1.5 18.394.595 24.656c-.44 3.04-.568 3.66-.594 19.188-.01 5.176 0 11.988 0 21.125 0 27.12.03 35.05.16 36.59.45 5.42 1.3 8.83 3.1 12.56 3.44 7.14 10.01 12.5 17.75 14.5 2.68.69 5.64 1.07 9.44 1.25 1.61.07 18.02.12 34.44.12 16.42 0 32.84-.02 34.41-.1 4.4-.207 6.955-.55 9.78-1.28 7.79-2.01 14.24-7.29 17.75-14.53 1.765-3.64 2.66-7.18 3.065-12.317.088-1.12.125-18.977.125-36.81 0-17.836-.04-35.66-.128-36.78-.41-5.22-1.305-8.73-3.127-12.44-1.495-3.037-3.155-5.305-5.565-7.624C116.9 4 111.64 1.5 105.372.596 102.335.157 101.73.027 86.19 0H65.03z" transform="translate(1.004 1)"/>
                            <path fill="#fff" d="M66.004 18c-13.036 0-14.672.057-19.792.29-5.11.234-8.598 1.043-11.65 2.23-3.157 1.226-5.835 2.866-8.503 5.535-2.67 2.668-4.31 5.346-5.54 8.502-1.19 3.053-2 6.542-2.23 11.65C18.06 51.327 18 52.964 18 66s.058 14.667.29 19.787c.235 5.11 1.044 8.598 2.23 11.65 1.227 3.157 2.867 5.835 5.536 8.503 2.667 2.67 5.345 4.314 8.5 5.54 3.054 1.187 6.543 1.996 11.652 2.23 5.12.233 6.755.29 19.79.29 13.037 0 14.668-.057 19.788-.29 5.11-.234 8.602-1.043 11.656-2.23 3.156-1.226 5.83-2.87 8.497-5.54 2.67-2.668 4.31-5.346 5.54-8.502 1.18-3.053 1.99-6.542 2.23-11.65.23-5.12.29-6.752.29-19.788 0-13.036-.06-14.672-.29-19.792-.24-5.11-1.05-8.598-2.23-11.65-1.23-3.157-2.87-5.835-5.54-8.503-2.67-2.67-5.34-4.31-8.5-5.535-3.06-1.187-6.55-1.996-11.66-2.23-5.12-.233-6.75-.29-19.79-.29zm-4.306 8.65c1.278-.002 2.704 0 4.306 0 12.816 0 14.335.046 19.396.276 4.68.214 7.22.996 8.912 1.653 2.24.87 3.837 1.91 5.516 3.59 1.68 1.68 2.72 3.28 3.592 5.52.657 1.69 1.44 4.23 1.653 8.91.23 5.06.28 6.58.28 19.39s-.05 14.33-.28 19.39c-.214 4.68-.996 7.22-1.653 8.91-.87 2.24-1.912 3.835-3.592 5.514-1.68 1.68-3.275 2.72-5.516 3.59-1.69.66-4.232 1.44-8.912 1.654-5.06.23-6.58.28-19.396.28-12.817 0-14.336-.05-19.396-.28-4.68-.216-7.22-.998-8.913-1.655-2.24-.87-3.84-1.91-5.52-3.59-1.68-1.68-2.72-3.276-3.592-5.517-.657-1.69-1.44-4.23-1.653-8.91-.23-5.06-.276-6.58-.276-19.398s.046-14.33.276-19.39c.214-4.68.996-7.22 1.653-8.912.87-2.24 1.912-3.84 3.592-5.52 1.68-1.68 3.28-2.72 5.52-3.592 1.692-.66 4.233-1.44 8.913-1.655 4.428-.2 6.144-.26 15.09-.27zm29.928 7.97c-3.18 0-5.76 2.577-5.76 5.758 0 3.18 2.58 5.76 5.76 5.76 3.18 0 5.76-2.58 5.76-5.76 0-3.18-2.58-5.76-5.76-5.76zm-25.622 6.73c-13.613 0-24.65 11.037-24.65 24.65 0 13.613 11.037 24.645 24.65 24.645C79.617 90.645 90.65 79.613 90.65 66S79.616 41.35 66.003 41.35zm0 8.65c8.836 0 16 7.163 16 16 0 8.836-7.164 16-16 16-8.837 0-16-7.164-16-16 0-8.837 7.163-16 16-16z"/>
                        </svg></a>
                    </div>
                </div>

                <div className="md:w-1/4  ft md:mt-0 md:text-right title">

                    <p className="mr-5 text-xs font-medium text-white">  Powered by:</p>
                    <div className="md:flex justify-end items-center">
                       <a href="https://zwlsoftware.nl" target="_blank">  <Image src={require('../img/zwlBL.png')} width={180} ></Image></a>
                    </div>
                </div>
            </div>
        </>);
    }
}

export default Home;