import React, { useState, useEffect } from 'react';
import './tailwind.css'; // Import the Tailwind CSS file
import AOS from 'aos';
import 'aos/dist/aos.css';
import './index.css';
import './main.min.css';
import { MantineProvider, Text } from '@mantine/core';
import Button1 from './Button';
import { useHotkeys } from '@mantine/hooks';
import { nprogress, NavigationProgress } from '@mantine/nprogress';


import Render from './Render';

export default function App() {

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  
  const [themeMode, setThemeMode] = useState<'light' | 'dark'>('light');

  const toggleTheme = () => {
    setThemeMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
  };
  
  let current = 0;
  const setNav = (amount: number) => {
    current += amount;
    if (current > 100) {
      current = 0;
      nprogress.reset()
    }
    else {
      nprogress.increment(amount);
    }
  };


  useHotkeys([['ArrowRight ', () => setNav(10)]]);

  useHotkeys([['m', () => toggleTheme()]]);
 
  
  return (
    <MantineProvider theme={{
      colorScheme: themeMode,
      fontFamily: 'Greycliff CF, sans-serif',
      colors: {
        'ocean-blue': ['#7AD1DD', '#5FCCDB', '#44CADC', '#2AC9DE', '#1AC2D9', '#11B7CD', '#09ADC3', '#0E99AC', '#128797', '#147885'],
        'bright-pink': ['#F0BBDD', '#ED9BCF', '#EC7CC3', '#ED5DB8', '#F13EAF', '#F71FA7', '#FF00A1', '#E00890', '#C50E82', '#AD1374'],
        'bright-orange': ['#FFD8A8', '#FFC078', '#FFA94D', '#FF922B', '#FF922B', '#FF922B', '#FF922B', '#FF922B', '#FF922B', '#FF922B']
      },
    }} withGlobalStyles withNormalizeCSS>
      <NavigationProgress size={10} />
      <Render></Render>
    </MantineProvider>
  );
}
