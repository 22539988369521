import { Button, ButtonProps } from "@mantine/core";

interface Button1Props extends ButtonProps {
    text: string;
    variant?: 'default' | 'primary' | 'secondary' | 'filled' ;
    disabled?: boolean;
    onClick?: () => void;
  }

function Button1({text, ...props}: Button1Props)
{
    return <Button {...props}>{text}</Button>;
}

export default Button1;