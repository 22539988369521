 
import '../index.css';
import Button1 from '../Button';
export default function Game() {
    return (<>
        <div className="p-5 flex justify-center items-center">
          
                <Button1 text='Check' color='bright-orange' className='w-[90%] md:w-1/3 absolute bottom-40'></Button1>
    
        </div>

    </>);
}